(function () {
    'use strict';

    /**
     PresetService factory
     STATELESS and IMMUTABLE, please leave like so
     produces models prototyped with delete/update functionality.

     which should be removed as soon as we have consistent API convention
     */
    angular.module('aerosApp').factory('PresetService', PresetService).factory('StubPresetService', StubPresetService);

    PresetService.$inject = ['$injector', 'StubPresetService'];

    function PresetService($injector, StubPresetService) {
        return function (toolType) {
            var sname = toolType + 'PresetService';
            return $injector.has(sname) ?
                $injector.get(sname) : StubPresetService;
        };
    }

// Stub service for unknown types
    StubPresetService.$inject = ['$q'];

    function StubPresetService($q) {
        return {
            fetch,
            updateDefinition
        };

        function updateDefinition(definition) {
            return disableFiberModeOnEdit(definition);
        }

        function disableFiberModeOnEdit(definition) {
            const definitionToUpdate = _.cloneDeep(definition);

            const otdrFiberModeDescription = definitionToUpdate['OTDR']
                .properties.find(property => property.fieldName === 'fiberMode')
                .fieldDescription;

            otdrFiberModeDescription.disabledOnEdit = true;

            return definitionToUpdate;
        }

        function fetch() {
            var defer = $q.defer();
            defer.resolve([]);
            return {$promise: defer.promise};
        }
    }

}());
